<template>
  <default>
    <!-- Breadcrumbs (Unchanged as per instructions) -->
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class="primary">
        <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard</v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip active-class="false" :to="{ name: 'Products' }">Products</v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip :to="{ name: 'Imarika' }">{{ $options.name }}</v-chip>
      </v-chip-group>
    </div>

    <!-- Main Card for Imarika Plan Calculator -->
    <v-card elevation="2" class="main-card tw-px-4 md:tw-px-8 tw-py-6 md:tw-py-8 tw-rounded-lg tw-shadow-lg tw-bg-white">
      <!-- Title Section with Icon -->
      <div class="tw-flex tw-items-center tw-mb-6">
        <img src="/images/icons/top-icon.png" alt="Imarika Icon" class="tw-w-10 tw-h-10 tw-mr-3" />
        <h2 class="tw-text-2xl tw-font-semibold c-blue-text">Imarika Plan Calculator</h2>
      </div>

      <!-- Form Container -->
      <div class="form-container tw-w-full tw-flex tw-flex-wrap tw-py-4 md:tw-py-6 tw-bg-gray-50 tw-rounded-md tw-shadow-inner lg:tw-shadow-lg">
        <keep-alive>
          <component
            :is="current"
            :productData="productData"
            :benefits="benefits"
            :terms="terms"
            :quoteSummaryData="quoteSummaryData"
            @to-quote-summary="toQuoteSummary"
          ></component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import BackButton from '../../components/BackButton';
import QuoteSummary from '../../components/rfq/QuoteSummary';
import ClientQuotation from '../../components/rfq/ClientQuotation';
import ProductMixin from '@/mixins/ProductMixin';
import savedQuoteMixin from '@/mixins/savedQuoteMixin';

export default {
  mounted() {
    this.checkSavedQuote('imarika');
  },
  name: 'ImarikaPlan',
  metaInfo: {
    title: 'RFQ - Imarika Plan',
  },
  components: {
    QuoteSummary,
    BackButton,
    CardTitle,
    Default,
    ClientQuotation,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      current: ClientQuotation,
      productData: { prodCode: 2013104, optionCode: 2012146 },
      benefits: [
        { coverName: 'Accidental Death', coverProdCode: 2013513 },
        { coverName: 'Total & Permanent Disability', coverProdCode: 2013512 },
        { coverName: 'Waiver of Premium', coverProdCode: 2013511 },
        { coverName: 'Medical Reimbursement', coverProdCode: 2013515 },
        { coverName: 'Critical Illness', coverProdCode: 2013514 },
        { coverName: 'Retrenchment', coverProdCode: 2016612 },
      ],
      terms: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      quoteSummaryData: {},
    };
  },
};
</script>

<style scoped>
/* Main card styling */
.main-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 2rem;
}

/* Form container styling */
.form-container {
  background-color: #f9fafb;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.05);
}
</style>
